import React from 'react';

const TarotVideoLading = ({ view }) => {
  const [loadingView, setLoadingView] = React.useState(false);
  React.useEffect(() => {
    setLoadingView(view);
  }, [view]);
  return (
    <React.Fragment>
      {
        loadingView && (
          <div className="video-loading-screen">
            <div className="video-loading-odd">뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에</div>
            <br />
            <div className="video-loading-even">으헤으허으헤으헤으허으헤으허으헤으헤으허으헤으허으헤으헤으허으헤으허으헤으헤으허으헤으허으헤으헤으허으헤으허으헤으헤으허으헤으허으헤으헤으허으헤으허으헤으헤으허으헤으허으헤으헤으허으헤으허으헤으헤으허으헤으허으헤으헤으허으헤으허으헤으헤으허으헤으허으헤으헤으허으헤으허</div>
            <br />
            <div className="video-loading-odd">뿌꾸루뚜구뚜루꾸루뿌꾸루뚜구뚜루꾸루뿌꾸루뚜구뚜루꾸루뿌꾸루뚜구뚜루꾸루뿌꾸루뚜구뚜루꾸루뿌꾸루뚜구뚜루꾸루뿌꾸루뚜구뚜루꾸루뿌꾸루뚜구뚜루꾸루뿌꾸루뚜구뚜루꾸루뿌꾸루뚜구뚜루꾸루뿌꾸루뚜구뚜루꾸루뿌꾸루뚜구뚜루꾸루뿌꾸루뚜구뚜루꾸루뿌꾸루뚜구뚜루꾸루뿌꾸루뚜구뚜루꾸루</div>
            <br />
            <div className="video-loading-odd">크우커쿠으쿠어쿠으크우커쿠으쿠어쿠으크우커쿠으쿠어쿠으크우커쿠으쿠어쿠으크우커쿠으쿠어쿠으크우커쿠으쿠어쿠으크우커쿠으쿠어쿠으크우커쿠으쿠어쿠으크우커쿠으쿠어쿠으크우커쿠으쿠어쿠으크우커쿠으쿠어쿠으크우커쿠으쿠어쿠으크우커쿠으쿠어쿠으크우커쿠으쿠어쿠으크우커쿠으쿠어쿠으</div>
            <br />
            <div className="video-loading-even">퓨수퓨슈우퓨수피이시퓨수퓨슈우퓨수피이시퓨수퓨슈우퓨수피이시퓨수퓨슈우퓨수피이시퓨수퓨슈우퓨수피이시퓨수퓨슈우퓨수피이시퓨수퓨슈우퓨수피이시퓨수퓨슈우퓨수피이시퓨수퓨슈우퓨수피이시퓨수퓨슈우퓨수피이시퓨수퓨슈우퓨수피이시퓨수퓨슈우퓨수피이시퓨수퓨슈우퓨수피이시퓨수퓨슈</div>
            <br />
            <div className="video-loading-odd">뿌꾸루뚜구뚜루꾸루뿌꾸루뚜구뚜루꾸루뿌꾸루뚜구뚜루꾸루뿌꾸루뚜구뚜루꾸루뿌꾸루뚜구뚜루꾸루뿌꾸루뚜구뚜루꾸루뿌꾸루뚜구뚜루꾸루뿌꾸루뚜구뚜루꾸루뿌꾸루뚜구뚜루꾸루뿌꾸루뚜구뚜루꾸루뿌꾸루뚜구뚜루꾸루뿌꾸루뚜구뚜루꾸루뿌꾸루뚜구뚜루꾸루뿌꾸루뚜구뚜루꾸루뿌꾸루뚜구뚜루꾸루</div>
            <br />
            <div className="video-loading-odd">믿기지않겠지만저는정상인입니다믿기지않겠지만저는정상인입니다믿기지않겠지만저는정상인입니다믿기지않겠지만저는정상인입니다믿기지않겠지만저는정상인입니다믿기지않겠지만저는정상인입니다믿기지않겠지만저는정상인입니다믿기지않겠지만저는정상인입니다믿기지않겠지만저는정상인입니다</div>
            <br />
            <div className="video-loading-even">믿기지않겠지만저는정상인입니다믿기지않겠지만저는정상인입니다믿기지않겠지만저는정상인입니다믿기지않겠지만저는정상인입니다믿기지않겠지만저는정상인입니다믿기지않겠지만저는정상인입니다믿기지않겠지만저는정상인입니다믿기지않겠지만저는정상인입니다믿기지않겠지만저는정상인입니다</div>
            <br />
            <div className="video-loading-even">수루류수류수루류수류수류소소러슈루류수루류수류수루류수류수류소소러슈루류수루류수류수루류수류수류소소러슈루류수루류수류수루류수류수류소소러슈루류수루류수류수루류수류수류소소러슈루류수루류수류수루류수류수류소소러슈루류수루류수류수루류수류수류소소러슈루류수루류수류수루류수</div>
            <br />
            <div className="video-loading-odd">뿌꾸루뚜구뚜루꾸루뿌꾸루뚜구뚜루꾸루뿌꾸루뚜구뚜루꾸루뿌꾸루뚜구뚜루꾸루뿌꾸루뚜구뚜루꾸루뿌꾸루뚜구뚜루꾸루뿌꾸루뚜구뚜루꾸루뿌꾸루뚜구뚜루꾸루뿌꾸루뚜구뚜루꾸루뿌꾸루뚜구뚜루꾸루뿌꾸루뚜구뚜루꾸루뿌꾸루뚜구뚜루꾸루뿌꾸루뚜구뚜루꾸루뿌꾸루뚜구뚜루꾸루뿌꾸루뚜구뚜루꾸루</div>
            <br />
            <div className="video-loading-even">후헤헤흐헤헤후흐헤흐헤후흐흐헤헤헤후후후헤헤흐헤후헤후헤헤흐헤헤후흐헤흐헤후흐흐헤헤헤후후후헤헤흐헤후헤후헤헤흐헤헤후흐헤흐헤후흐흐헤헤헤후후후헤헤흐헤후헤후헤헤흐헤헤후흐헤흐헤후흐흐헤헤헤후후후헤헤흐헤후헤후헤헤흐헤헤후흐헤흐헤후흐흐헤헤헤후후후헤헤흐헤후헤후헤헤흐헤</div>
            <br />
            <div className="video-loading-odd">뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에</div>
            <br />
            <div className="video-loading-even">으헤으허으헤으헤으허으헤으허으헤으헤으허으헤으허으헤으헤으허으헤으허으헤으헤으허으헤으허으헤으헤으허으헤으허으헤으헤으허으헤으허으헤으헤으허으헤으허으헤으헤으허으헤으허으헤으헤으허으헤으허으헤으헤으허으헤으허으헤으헤으허으헤으허으헤으헤으허으헤으허으헤으헤으허으헤으허</div>
            <br />
            <div className="video-loading-odd">뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에</div>
            <br />
            <div className="video-loading-even">으헤으허으헤으헤으허으헤으허으헤으헤으허으헤으허으헤으헤으허으헤으허으헤으헤으허으헤으허으헤으헤으허으헤으허으헤으헤으허으헤으허으헤으헤으허으헤으허으헤으헤으허으헤으허으헤으헤으허으헤으허으헤으헤으허으헤으허으헤으헤으허으헤으허으헤으헤으허으헤으허으헤으헤으허으헤으허</div>
            <br />
            <div className="video-loading-odd">뿌꾸루뚜구뚜루꾸루뿌꾸루뚜구뚜루꾸루뿌꾸루뚜구뚜루꾸루뿌꾸루뚜구뚜루꾸루뿌꾸루뚜구뚜루꾸루뿌꾸루뚜구뚜루꾸루뿌꾸루뚜구뚜루꾸루뿌꾸루뚜구뚜루꾸루뿌꾸루뚜구뚜루꾸루뿌꾸루뚜구뚜루꾸루뿌꾸루뚜구뚜루꾸루뿌꾸루뚜구뚜루꾸루뿌꾸루뚜구뚜루꾸루뿌꾸루뚜구뚜루꾸루뿌꾸루뚜구뚜루꾸루</div>
            <br />
            <div className="video-loading-odd">크우커쿠으쿠어쿠으크우커쿠으쿠어쿠으크우커쿠으쿠어쿠으크우커쿠으쿠어쿠으크우커쿠으쿠어쿠으크우커쿠으쿠어쿠으크우커쿠으쿠어쿠으크우커쿠으쿠어쿠으크우커쿠으쿠어쿠으크우커쿠으쿠어쿠으크우커쿠으쿠어쿠으크우커쿠으쿠어쿠으크우커쿠으쿠어쿠으크우커쿠으쿠어쿠으크우커쿠으쿠어쿠으</div>
            <br />
            <div className="video-loading-even">퓨수퓨슈우퓨수피이시퓨수퓨슈우퓨수피이시퓨수퓨슈우퓨수피이시퓨수퓨슈우퓨수피이시퓨수퓨슈우퓨수피이시퓨수퓨슈우퓨수피이시퓨수퓨슈우퓨수피이시퓨수퓨슈우퓨수피이시퓨수퓨슈우퓨수피이시퓨수퓨슈우퓨수피이시퓨수퓨슈우퓨수피이시퓨수퓨슈우퓨수피이시퓨수퓨슈우퓨수피이시퓨수퓨슈</div>
            <br />
            <div className="video-loading-odd">뿌꾸루뚜구뚜루꾸루뿌꾸루뚜구뚜루꾸루뿌꾸루뚜구뚜루꾸루뿌꾸루뚜구뚜루꾸루뿌꾸루뚜구뚜루꾸루뿌꾸루뚜구뚜루꾸루뿌꾸루뚜구뚜루꾸루뿌꾸루뚜구뚜루꾸루뿌꾸루뚜구뚜루꾸루뿌꾸루뚜구뚜루꾸루뿌꾸루뚜구뚜루꾸루뿌꾸루뚜구뚜루꾸루뿌꾸루뚜구뚜루꾸루뿌꾸루뚜구뚜루꾸루뿌꾸루뚜구뚜루꾸루</div>
            <br />
            <div className="video-loading-odd">믿기지않겠지만저는정상인입니다믿기지않겠지만저는정상인입니다믿기지않겠지만저는정상인입니다믿기지않겠지만저는정상인입니다믿기지않겠지만저는정상인입니다믿기지않겠지만저는정상인입니다믿기지않겠지만저는정상인입니다믿기지않겠지만저는정상인입니다믿기지않겠지만저는정상인입니다</div>
            <br />
            <div className="video-loading-even">믿기지않겠지만저는정상인입니다믿기지않겠지만저는정상인입니다믿기지않겠지만저는정상인입니다믿기지않겠지만저는정상인입니다믿기지않겠지만저는정상인입니다믿기지않겠지만저는정상인입니다믿기지않겠지만저는정상인입니다믿기지않겠지만저는정상인입니다믿기지않겠지만저는정상인입니다</div>
            <br />
            <div className="video-loading-even">수루류수류수루류수류수류소소러슈루류수루류수류수루류수류수류소소러슈루류수루류수류수루류수류수류소소러슈루류수루류수류수루류수류수류소소러슈루류수루류수류수루류수류수류소소러슈루류수루류수류수루류수류수류소소러슈루류수루류수류수루류수류수류소소러슈루류수루류수류수루류수</div>
            <br />
            <div className="video-loading-odd">뿌꾸루뚜구뚜루꾸루뿌꾸루뚜구뚜루꾸루뿌꾸루뚜구뚜루꾸루뿌꾸루뚜구뚜루꾸루뿌꾸루뚜구뚜루꾸루뿌꾸루뚜구뚜루꾸루뿌꾸루뚜구뚜루꾸루뿌꾸루뚜구뚜루꾸루뿌꾸루뚜구뚜루꾸루뿌꾸루뚜구뚜루꾸루뿌꾸루뚜구뚜루꾸루뿌꾸루뚜구뚜루꾸루뿌꾸루뚜구뚜루꾸루뿌꾸루뚜구뚜루꾸루뿌꾸루뚜구뚜루꾸루</div>
            <br />
            <div className="video-loading-even">후헤헤흐헤헤후흐헤흐헤후흐흐헤헤헤후후후헤헤흐헤후헤후헤헤흐헤헤후흐헤흐헤후흐흐헤헤헤후후후헤헤흐헤후헤후헤헤흐헤헤후흐헤흐헤후흐흐헤헤헤후후후헤헤흐헤후헤후헤헤흐헤헤후흐헤흐헤후흐흐헤헤헤후후후헤헤흐헤후헤후헤헤흐헤헤후흐헤흐헤후흐흐헤헤헤후후후헤헤흐헤후헤후헤헤흐헤</div>
            <br />
            <div className="video-loading-odd">뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에</div>
            <br />
            <div className="video-loading-even">으헤으허으헤으헤으허으헤으허으헤으헤으허으헤으허으헤으헤으허으헤으허으헤으헤으허으헤으허으헤으헤으허으헤으허으헤으헤으허으헤으허으헤으헤으허으헤으허으헤으헤으허으헤으허으헤으헤으허으헤으허으헤으헤으허으헤으허으헤으헤으허으헤으허으헤으헤으허으헤으허으헤으헤으허으헤으허</div>
            <br />
            <div className="video-loading-even">으헤으허으헤으헤으허으헤으허으헤으헤으허으헤으허으헤으헤으허으헤으허으헤으헤으허으헤으허으헤으헤으허으헤으허으헤으헤으허으헤으허으헤으헤으허으헤으허으헤으헤으허으헤으허으헤으헤으허으헤으허으헤으헤으허으헤으허으헤으헤으허으헤으허으헤으헤으허으헤으허으헤으헤으허으헤으허</div>
            <br />
            <div className="video-loading-odd">뿌꾸루뚜구뚜루꾸루뿌꾸루뚜구뚜루꾸루뿌꾸루뚜구뚜루꾸루뿌꾸루뚜구뚜루꾸루뿌꾸루뚜구뚜루꾸루뿌꾸루뚜구뚜루꾸루뿌꾸루뚜구뚜루꾸루뿌꾸루뚜구뚜루꾸루뿌꾸루뚜구뚜루꾸루뿌꾸루뚜구뚜루꾸루뿌꾸루뚜구뚜루꾸루뿌꾸루뚜구뚜루꾸루뿌꾸루뚜구뚜루꾸루뿌꾸루뚜구뚜루꾸루뿌꾸루뚜구뚜루꾸루</div>
            <br />
            <div className="video-loading-odd">크우커쿠으쿠어쿠으크우커쿠으쿠어쿠으크우커쿠으쿠어쿠으크우커쿠으쿠어쿠으크우커쿠으쿠어쿠으크우커쿠으쿠어쿠으크우커쿠으쿠어쿠으크우커쿠으쿠어쿠으크우커쿠으쿠어쿠으크우커쿠으쿠어쿠으크우커쿠으쿠어쿠으크우커쿠으쿠어쿠으크우커쿠으쿠어쿠으크우커쿠으쿠어쿠으크우커쿠으쿠어쿠으</div>
            <br />
            <div className="video-loading-even">퓨수퓨슈우퓨수피이시퓨수퓨슈우퓨수피이시퓨수퓨슈우퓨수피이시퓨수퓨슈우퓨수피이시퓨수퓨슈우퓨수피이시퓨수퓨슈우퓨수피이시퓨수퓨슈우퓨수피이시퓨수퓨슈우퓨수피이시퓨수퓨슈우퓨수피이시퓨수퓨슈우퓨수피이시퓨수퓨슈우퓨수피이시퓨수퓨슈우퓨수피이시퓨수퓨슈우퓨수피이시퓨수퓨슈</div>
            <br />
            <div className="video-loading-odd">뿌꾸루뚜구뚜루꾸루뿌꾸루뚜구뚜루꾸루뿌꾸루뚜구뚜루꾸루뿌꾸루뚜구뚜루꾸루뿌꾸루뚜구뚜루꾸루뿌꾸루뚜구뚜루꾸루뿌꾸루뚜구뚜루꾸루뿌꾸루뚜구뚜루꾸루뿌꾸루뚜구뚜루꾸루뿌꾸루뚜구뚜루꾸루뿌꾸루뚜구뚜루꾸루뿌꾸루뚜구뚜루꾸루뿌꾸루뚜구뚜루꾸루뿌꾸루뚜구뚜루꾸루뿌꾸루뚜구뚜루꾸루</div>
            <br />
            <div className="video-loading-odd">믿기지않겠지만저는정상인입니다믿기지않겠지만저는정상인입니다믿기지않겠지만저는정상인입니다믿기지않겠지만저는정상인입니다믿기지않겠지만저는정상인입니다믿기지않겠지만저는정상인입니다믿기지않겠지만저는정상인입니다믿기지않겠지만저는정상인입니다믿기지않겠지만저는정상인입니다</div>
            <br />
            <div className="video-loading-even">믿기지않겠지만저는정상인입니다믿기지않겠지만저는정상인입니다믿기지않겠지만저는정상인입니다믿기지않겠지만저는정상인입니다믿기지않겠지만저는정상인입니다믿기지않겠지만저는정상인입니다믿기지않겠지만저는정상인입니다믿기지않겠지만저는정상인입니다믿기지않겠지만저는정상인입니다</div>
            <br />
            <div className="video-loading-even">수루류수류수루류수류수류소소러슈루류수루류수류수루류수류수류소소러슈루류수루류수류수루류수류수류소소러슈루류수루류수류수루류수류수류소소러슈루류수루류수류수루류수류수류소소러슈루류수루류수류수루류수류수류소소러슈루류수루류수류수루류수류수류소소러슈루류수루류수류수루류수</div>
            <br />
            <div className="video-loading-odd">뿌꾸루뚜구뚜루꾸루뿌꾸루뚜구뚜루꾸루뿌꾸루뚜구뚜루꾸루뿌꾸루뚜구뚜루꾸루뿌꾸루뚜구뚜루꾸루뿌꾸루뚜구뚜루꾸루뿌꾸루뚜구뚜루꾸루뿌꾸루뚜구뚜루꾸루뿌꾸루뚜구뚜루꾸루뿌꾸루뚜구뚜루꾸루뿌꾸루뚜구뚜루꾸루뿌꾸루뚜구뚜루꾸루뿌꾸루뚜구뚜루꾸루뿌꾸루뚜구뚜루꾸루뿌꾸루뚜구뚜루꾸루</div>
            <br />
            <div className="video-loading-even">후헤헤흐헤헤후흐헤흐헤후흐흐헤헤헤후후후헤헤흐헤후헤후헤헤흐헤헤후흐헤흐헤후흐흐헤헤헤후후후헤헤흐헤후헤후헤헤흐헤헤후흐헤흐헤후흐흐헤헤헤후후후헤헤흐헤후헤후헤헤흐헤헤후흐헤흐헤후흐흐헤헤헤후후후헤헤흐헤후헤후헤헤흐헤헤후흐헤흐헤후흐흐헤헤헤후후후헤헤흐헤후헤후헤헤흐헤</div>
            <br />
            <div className="video-loading-odd">뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에</div>
            <br />
            <div className="video-loading-even">으헤으허으헤으헤으허으헤으허으헤으헤으허으헤으허으헤으헤으허으헤으허으헤으헤으허으헤으허으헤으헤으허으헤으허으헤으헤으허으헤으허으헤으헤으허으헤으허으헤으헤으허으헤으허으헤으헤으허으헤으허으헤으헤으허으헤으허으헤으헤으허으헤으허으헤으헤으허으헤으허으헤으헤으허으헤으허</div>
            <br />
            <div className="video-loading-odd">뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에</div>
            <br />
            <div className="video-loading-even">뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에</div>
            <br />
            <div className="video-loading-even">뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에</div>
            <br />
            <div className="video-loading-odd">뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에</div>
            <br />
            <div className="video-loading-odd">뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에</div>
            <br />
            <div className="video-loading-even">뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에</div>
            <br />
            <div className="video-loading-even">뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에뿌에에에에</div>
            <br />
          </div>
        )
      }
    </React.Fragment>
  );
};

export default TarotVideoLading;
